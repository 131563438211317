<template>
  <div>
    <p class="text-lg text-dark">Change pin</p>
    <Input
      id="old-pin"
      type="password"
      width="w-full"
      placeholder="Old pin"
      revealPassword
      class="mt-6"
      v-model="card.oldPin"
      errorText="Pin must contain 4 digits"
      :validation="rules.oldPin"
      autoComplete="new-password"
      number
      maxlength="4"
    />
    <Input
      id="new-pin"
      type="password"
      width="w-full"
      placeholder="New 4 digit pin"
      class="mt-5"
      revealPassword
      v-model="card.newPin"
      errorText="New pin must contain 4 digits"
      :validation="rules.newPin"
      autoComplete="new-password"
      number
      maxlength="4"
    />
    <Input
      id="confirm-pin"
      type="password"
      width="w-full"
      placeholder="Confirm New Pin"
      class="mt-5"
      revealPassword
      v-model="confirmPin"
      errorText="Pins do not match"
      :validation="rules.confirmPin"
      autoComplete="new-password"
      number
      maxlength="4"
    />
    <Button
      width="w-full"
      text="Change pin"
      class="mt-5"
      :loading="loading"
      :disabled="disableButton"
      @click="changePin()"
    />
    <div class="flex items-center justify-center mt-5">
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
      >
        <path
          d="M9.10337 5.61057H8.41963V3.77724C8.41963 2.08996 6.88876 0.72168 5.00097 0.72168C3.11319 0.72168 1.58231 2.08996 1.58231 3.77724V5.61057H0.898576C0.522523 5.61057 0.214844 5.88557 0.214844 6.22168V11.1106C0.214844 11.4467 0.522523 11.7217 0.898576 11.7217H9.10337C9.47942 11.7217 9.7871 11.4467 9.7871 11.1106V6.22168C9.7871 5.88557 9.47942 5.61057 9.10337 5.61057ZM2.94977 5.61057V3.77724C2.94977 2.76462 3.86803 1.9439 5.00097 1.9439C6.13392 1.9439 7.05217 2.76462 7.05217 3.77724V5.61057H2.94977Z"
          fill="black"
          fill-opacity="0.5"
        />
      </svg>
      <p class="text-xs text-grey">Secured by Zilla</p>
    </div>
  </div>
</template>
<script>
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { mapActions } from "vuex";
export default {
  components: {
    Input,
    Button,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      card: {
        oldPin: "",
        newPin: "",
      },
      confirmPin: "",
      loading: false,
    };
  },
  computed: {
    rules() {
      return {
        oldPin: this.card.oldPin.length === 4,
        newPin: this.card.newPin.length === 4,
        confirmPin: this.confirmPin === this.card.newPin
        // blocked: this.details.status !== 'BLOCKED'
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  methods: {
    ...mapActions("card", ["changeCardPin"]),
    ...mapActions("notification", ["showAlert"]),
    changePin() {
      this.loading = true;
      this.changeCardPin({
        id: this.details.id,
        data: this.card,
      })
        .then((res) => {
          this.loading = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Your pin has been changed successfully`,
          }).then((res) => {
            this.card = {
              oldPin: "",
              newPin: "",
            };
            this.confirmPin = "";
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
