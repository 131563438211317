var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-lg text-dark" }, [_vm._v("Change pin")]),
      _c("Input", {
        staticClass: "mt-6",
        attrs: {
          id: "old-pin",
          type: "password",
          width: "w-full",
          placeholder: "Old pin",
          revealPassword: "",
          errorText: "Pin must contain 4 digits",
          validation: _vm.rules.oldPin,
          autoComplete: "new-password",
          number: "",
          maxlength: "4",
        },
        model: {
          value: _vm.card.oldPin,
          callback: function ($$v) {
            _vm.$set(_vm.card, "oldPin", $$v)
          },
          expression: "card.oldPin",
        },
      }),
      _c("Input", {
        staticClass: "mt-5",
        attrs: {
          id: "new-pin",
          type: "password",
          width: "w-full",
          placeholder: "New 4 digit pin",
          revealPassword: "",
          errorText: "New pin must contain 4 digits",
          validation: _vm.rules.newPin,
          autoComplete: "new-password",
          number: "",
          maxlength: "4",
        },
        model: {
          value: _vm.card.newPin,
          callback: function ($$v) {
            _vm.$set(_vm.card, "newPin", $$v)
          },
          expression: "card.newPin",
        },
      }),
      _c("Input", {
        staticClass: "mt-5",
        attrs: {
          id: "confirm-pin",
          type: "password",
          width: "w-full",
          placeholder: "Confirm New Pin",
          revealPassword: "",
          errorText: "Pins do not match",
          validation: _vm.rules.confirmPin,
          autoComplete: "new-password",
          number: "",
          maxlength: "4",
        },
        model: {
          value: _vm.confirmPin,
          callback: function ($$v) {
            _vm.confirmPin = $$v
          },
          expression: "confirmPin",
        },
      }),
      _c("Button", {
        staticClass: "mt-5",
        attrs: {
          width: "w-full",
          text: "Change pin",
          loading: _vm.loading,
          disabled: _vm.disableButton,
        },
        on: {
          click: function ($event) {
            return _vm.changePin()
          },
        },
      }),
      _c("div", { staticClass: "flex items-center justify-center mt-5" }, [
        _c(
          "svg",
          {
            staticClass: "mr-2",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "10",
              height: "12",
              viewBox: "0 0 10 12",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M9.10337 5.61057H8.41963V3.77724C8.41963 2.08996 6.88876 0.72168 5.00097 0.72168C3.11319 0.72168 1.58231 2.08996 1.58231 3.77724V5.61057H0.898576C0.522523 5.61057 0.214844 5.88557 0.214844 6.22168V11.1106C0.214844 11.4467 0.522523 11.7217 0.898576 11.7217H9.10337C9.47942 11.7217 9.7871 11.4467 9.7871 11.1106V6.22168C9.7871 5.88557 9.47942 5.61057 9.10337 5.61057ZM2.94977 5.61057V3.77724C2.94977 2.76462 3.86803 1.9439 5.00097 1.9439C6.13392 1.9439 7.05217 2.76462 7.05217 3.77724V5.61057H2.94977Z",
                fill: "black",
                "fill-opacity": "0.5",
              },
            }),
          ]
        ),
        _c("p", { staticClass: "text-xs text-grey" }, [
          _vm._v("Secured by Zilla"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }